import { Target, TypedController, Value } from "@vytant/stimulus-decorators";
import { addSeconds, formatDistanceStrict } from "date-fns";
import { de, enGB, fr, nlBE, uk, zhCN } from "date-fns/locale";
import { Controller } from "stimulus";

@TypedController
export default class extends Controller {
  @Target readonly passwordInputTarget: HTMLInputElement;
  @Target readonly sendMagicLinkButtonTarget: HTMLButtonElement;
  @Target readonly submitButtonTarget!: HTMLButtonElement;
  @Target readonly remainingTimeLockedMessageTarget: HTMLElement;

  @Value(Number) secondsRemainingLockedValue = 0;
  @Value(String) currentLanguageValue = 'de';

  connect() {
    this.passwordInputTarget.addEventListener("input", this.updateSubmitButtonVisability.bind(this));

    if (this.secondsRemainingLockedValue > 0) {
      this.disablePasswordInputAndCountdown();
      this.setRemainingTimeLockedMessage();
    }
  }

  updateSubmitButtonVisability() {
    const value = this.passwordInputTarget.value;
    if (value.length > 0) {
      this.submitButtonTarget.removeAttribute("disabled");
    } else {
      this.submitButtonTarget.setAttribute("disabled", "true");
    }
  }

  disablePasswordInputAndCountdown() {
    this.passwordInputTarget.setAttribute("disabled", "true");
    this.sendMagicLinkButtonTarget.setAttribute("disabled", "true");

    const interval = setInterval(() => {
      this.secondsRemainingLockedValue = this.secondsRemainingLockedValue - 1;

      if (this.secondsRemainingLockedValue <= 0) {
        clearInterval(interval);

        this.passwordInputTarget.disabled = false;
        this.sendMagicLinkButtonTarget.disabled = false;
        this.remainingTimeLockedMessageTarget.classList.add("hidden");
      }

      this.setRemainingTimeLockedMessage();
    }, 1000);
  }

  setRemainingTimeLockedMessage() {
    const timeSpan = this.remainingTimeLockedMessageTarget.querySelector("#remaining-time");

    const time = addSeconds(new Date(), this.secondsRemainingLockedValue);
    timeSpan!.innerHTML = formatDistanceStrict(new Date(), time, { locale: this.dateFnsLocale(this.currentLanguageValue) });
  }

  dateFnsLocale(lang: string) {
    switch (lang) {
      case 'de':
        return de;
      case 'en':
        return enGB;
      case 'fr':
        return fr;
      case 'nl':
        return nlBE;
      case 'uk':
        return uk;
      case 'zh':
        return zhCN;
      default:
        return de;
    }
  }
}
